
  /* General styles for the treatment page */
.treatment-page {
    min-height: 80vh; /* Ensure the height of the page is at least the height of the viewport */
    padding: 20px; /* Optional: Add padding around the content */
    background-color: #cdddee; /* Soft background for contrast */
  }
  
  /* Style for the h1 heading */
  .treatment-page h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333; /* Dark text color */
  }
  
  /* Styles for the image slider */
  .slide-item {
    text-align: center;
    padding: 10px;
  }
  
  .treatment-image {
    width: 100%; /* Make images responsive */
    height: 200px; /* Set a fixed height for the images */
    object-fit: cover; /* Ensure images maintain aspect ratio and fit inside the container */
    border-radius: 8px; /* Optional: Add rounded corners */
  }
  
  .treatment-name {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #555; /* Slightly lighter text color */
  }
  
  /* Custom dot styling for the slider */
  .custom-dot {
    width: 10px;
    height: 10px;
    margin-top: 40px;
    border-radius: 50%;
    background-color: #555; /* Dot color */
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .treatment-image {
      height: 150px; /* Adjust image height for smaller screens */
    }
    
    .treatment-name {
      font-size: 1rem; /* Adjust font size for smaller screens */
    }
  }
  