.footer-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(rgb(33, 29, 74),rgb(9, 30, 54),rgb(54, 86, 114));
    color: #fff;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
  }
  
  .footer-section {
    flex: 1 1 20%;
    margin: 10px;
    text-align: center;
  }
  
  .footer-section h3 {
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .footer-section p {
    margin-bottom: 10px;
    font-size: 0.9rem;
    color: #ccc;
  }
  
  .social-links {
    list-style-type: none;
    padding: 0;
  }
  
  .social-links li {
    display: inline-block;
    margin: 0 10px;
  }
  
  .social-links li a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-links li a:hover {
    color: #007bff;
  }
  
  .footeraddress-box h3{
    flex: 1 1 20%;
    margin: 10px;
    text-align: center;
    color:white;
  }
  .footeraddress-box p{
    flex: 1 1 20%;
    margin: 10px;
    text-align: center;
    color:white;
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-section {
      flex: 1 1 45%; /* Adjust to two columns on smaller screens */
    }
  }
  
  @media (max-width: 500px) {
    .footer-section {
      flex: 1 1 100%; /* Stack each section on very small screens */
      text-align: left;
    }
  }
  