.video-carousel-container {
    margin: 0 auto;
    padding: 50px;
    background: linear-gradient(rgb(152, 151, 160),rgb(98, 133, 172),rgb(54, 86, 114));
  }
  
  .video-item {
    padding: 10px;
    text-align: center;
  }
  
  .video-item h3 {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  
  /* Centered button container */
  .view-all-videos {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  /* Button styles */
  .view-all-button {
    padding: 10px 20px;
    background-color: #020a13;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 250px;
    transition: background-color 0.3s ease;
  }
  
  .view-all-button:hover {
    background-color: #0056b3;
  }
  