/* General container styles */
.contact-container {
    margin-top: 30px;
    margin-bottom: 30px;
    /* background-color: #f9f9f9; */
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
}

/* Form styling */
.contact-form-container {
    /* background-color: #ffffff; */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

}

.contact-form-container h4 {
    margin-bottom: 20px;
    color: #00796b;
}

/* Input fields */
.form-control {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 15px;
}

/* Submit button */
button.btn-primary {
    background-color: #00796b;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

button.btn-primary:hover {
    background-color: #00564d;
}

/* Contact information boxes */
.contact-info-container {
    /* background-color: #ffffff; */
    padding: 30px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin-bottom: 32px;
}

.contact-info-container h4 {
    color: #06020e;
}

.info-box {
    background-color: #d2cfd8;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 6px;
    /* color: white; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-box h5 {
    color: #210606;
}

.info-box p {
    margin: 0;
    color: rgb(3, 15, 26);
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
    .contact-container {
        padding: 20px;
    }
    
    .contact-form-container, .contact-info-container {
        padding: 15px;
    }
}
