/* App.css */
body{
  padding:0;
  margin: 0;
}
.top-nav .contact-info span {
  font-size: 14px;
}

.top-nav .social-icons a {
  font-size: 18px;
}

.btn-primary {
  background-color: #1cba9f;
  border-color: #1cba9f;
}

.btn-primary:hover {
  background-color: #16a086;
  border-color: #16a086;
}
