/* Container with gradient background */
.cutis-container {
    background: linear-gradient(rgb(33, 29, 74), rgb(9, 30, 54), rgb(54, 86, 114));
    padding: 3rem;
}

/* Column styling */
.text-column {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Space between text boxes */
}

/* Text box styling */
.text-box {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
    padding: 1.5rem; /* Increased padding */
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
    transition: transform 0.3s, box-shadow 0.3s; /* Animation */
}

/* Hover effect for text boxes */
.text-box:hover {
    transform: translateY(-5px); /* Lift effect */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

/* Text styling */
.text-box h2 {
    color: rgb(33, 29, 74); /* Dark text color */
    margin-bottom: 0.5rem;
}

.image-description {
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(33, 29, 74); /* Dark text color for descriptions */
}

/* Responsive styling for medium screens */
@media (max-width: 1200px) {
    .cutis-container {
        padding: 2rem;
    }

    .text-column {
        gap: 1rem;
    }
}

/* Adjusting layout for smaller screens */
@media (max-width: 768px) {
    .cutis-row {
        flex-direction: column;
        gap: 1rem; /* Reduce space between rows */
    }

    .text-box {
        width: 100%; /* Maintain full width */
    }
}

/* Responsive styling for extra-small screens */
@media (max-width: 576px) {
    .text-box {
        width: 100%; /* Full width for smaller screens */
    }

    .image-description {
        font-size: 0.9rem; /* Slightly smaller text */
    }
}

/* Style for the h1 heading */
.cutis-container h1 {
    text-align: center;
    font-style: italic;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: white;
    animation: fadeIn 0.5s ease-in-out; /* Animation for heading */
}

/* Fade-in animation for the heading */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
