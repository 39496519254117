.clients-container {
  background: linear-gradient(rgb(33, 29, 74),rgb(9, 30, 54),rgb(54, 86, 114));
  display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .clients-box {
    border: 2px solid white;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 650px;
    height: 300px;
    transition: transform 0.3s ease;
  }
  
  .arrow {
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .client-text {
    color: white;
    font-size: 24px;
    flex: 1;
    font-weight: bold;
    text-align: center;
  }
  
  .view-all-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    background-color: rgb(52, 12, 87);
    color: rgb(234, 234, 243);
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .view-all-button:hover {
    background-color: lightgray;
  }
  