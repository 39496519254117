/* Container styles */
.review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #cdddee; /* Soft background for contrast */
    margin: 0 auto; /* Center the container */
  }
  
  /* Centered image at the top */
  .center-image img {
    width: 300px; /* Increase the size for better visibility */
    height: auto;
    margin-bottom: 40px; /* Add more spacing */
  }
  
  /* Flexbox for review boxes */
  .review-boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows boxes to wrap on smaller screens */
    gap: 20px;
  }
  
  /* Review box styles */
  .review-box {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    width: 350px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Review image styles */
  .review-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  /* Title styles */
  h3 {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Description styles */
  p {
    font-size: 0.95rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.5;
  }
  
  /* Button styles */
  .review-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #031325;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .review-button:hover {
    background-color: #35495e;
    transform: scale(1.05);
  }
  
  /* Animation: grow and shadow on hover */
  .review-box:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .review-boxes {
      flex-direction: column;
      align-items: center;
    }
    
    .review-box {
      width: 100%; /* Take full width on smaller screens */
      max-width: 500px;
    }
  
    .center-image img {
      width: 200px; /* Reduce image size for smaller screens */
    }
  }
  