.second-carousel-container {
  padding: 2rem;
}

/* Wrapper for the carousel */
.secondcarousel-wrapper {
  position: relative; /* For positioning the pseudo-elements */
  border: 5px solid #41ae98; /* Solid border */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensure content doesn't overflow */
  box-shadow: 0 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  height:350px;
}

/* Custom carousel image size */
.secondcustom-carousel-img {
  width: 100%; /* Full width */
  max-height: 500px; /* Set a maximum height */
  object-fit: cover; /* Ensures image fits nicely */

}

/* Title and description styles */
.secondcarousel-title {
  font-size: 35px; /* Adjusted for better fit */
  font-weight: bold;
  color: #0f0a0a;
  margin-top: 30px;
  word-wrap: break-word; /* Ensures title wraps */
}

.secondcarousel-description {
  font-size: 20px; /* Adjusted size */
  color: #554646;
}

/* Responsive text adjustment */
.second-carousel-text {
  padding-left: 20px; /* Add padding to the left */
}

@media (max-width: 768px) {
  .second-carousel-container {
    padding: 1rem; /* Less padding */
  }

  .secondcarousel-title {
    font-size: 22px; /* Smaller title size for smaller screens */
    margin-top: 10px; /* Less margin on top */
  }

  .secondcarousel-description {
    font-size: 14px; /* Smaller description size */
  }
}

@media (max-width: 576px) {
  .secondcarousel-title {
    font-size: 18px; /* Further reduce title size */
  }

  .secondcarousel-description {
    font-size: 12px; /* Further reduce description size */
  }

  .second-carousel-text {
    padding-left: 10px; /* Less padding on extra small screens */
  }
}
