.address-container {
    display: flex;
    flex-wrap: wrap; /* Allow the boxes to wrap on smaller screens */
    justify-content: space-between;
    padding: 20px;
    margin: 0 auto;
  }
  
  .address-box {
    flex: 1 1 23%; /* Flex property to allow wrapping, take 23% of the container on larger screens */
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Fourth box specific styles */
  .special-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .small-image {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .address-box h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .address-box p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .address-box i {
    margin-right: 5px;
    color: #007bff;
  }
  
  /* Responsive Design: Adjust layout for smaller screens */
  @media (max-width: 1024px) {
    .address-box {
      flex: 1 1 45%; /* Take 45% of the container width on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .address-box {
      flex: 1 1 100%; /* Stack the boxes vertically, each taking full width on small screens */
    }
  }
  