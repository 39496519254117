/* Top Navbar */
.top-nav {
  background: linear-gradient(45deg, rgb(48, 38, 61) 0%, rgb(117, 114, 169) 50%, rgb(13, 13, 79) 100%);
  color: white;
  padding: 10px 10px;
  margin-left: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;

}

.contact-info {
  margin-right: auto;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
}

.social-icons a {
  color: white;
  margin-right: 20px;
}

.logo img {
  max-width: 400px;
  height: auto;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Proper box-shadow value */
}


/* Navbar Styling */
.navbar {
  padding-left: 20px;
  padding-right: 20px;
}

.nav-link {
  color: #000 !important;
  margin-left: 15px;
  font-size: 16px; /* Adjust font size for responsiveness */
  text-transform: uppercase;
  transition: color 0.3s ease; 
  transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition for hover effect */
  position: relative;
}

/* Hover effect on menu items */
.nav-link:hover {
  background: #0b033d;
  border-top-left-radius:20px ;
  border-bottom-right-radius:20px ;

  color: #e8e7d9 !important; /* Yellow hover effect for the menu links */
}
.nav-link:hover::after {
  content: '';
  display: block;
  width: 100%; /* Full width underline */
  height: 2px; /* Thickness of the underline */
  background-color: rgb(2, 2, 17); /* Color of the underline */
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust the position of the underline */
}
.navbar-brand {
  margin-left: 10px;
}

.navbar-collapse {
  justify-content: flex-end;
}

/* Adjust toggle button for small screens */
@media (max-width: 768px) {
  .navbar-toggler {
    margin-left: auto; /* Align menu button to the right */
    background-color: white; /* Change the button color to white */
    border: none; /* Remove border */
  }

  .navbar-toggler-icon {
    background-color: rgb(211, 172, 228); /* Set the icon color */
  }

  /* Make menu items text color white on small screens */
  .nav-link {
    color: white !important;
  }

  /* Hover effect for small screens */
  .nav-link:hover {
    color: #f0a500 !important; /* Hover effect color on small screens */
  }
}

/* Offcanvas Menu */
.offcanvas-nav {
  background: linear-gradient(45deg, rgb(27, 17, 51) 0%, rgb(11, 28, 112) 50%, rgb(139, 139, 180) 100%);
  color: white;
  height: 400px;
  width: 300px;
  border-bottom-right-radius: 15px; /* Add border-radius to the offcanvas menu */
}

.offcanvas-nav .nav-link {
  color: rgb(50, 222, 20);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .top-nav {
    padding: 5px 10px;
  }
  
  .contact-info {
    font-size: 14px;
  }

  .nav-link {
    font-size: 14px;
    margin-left: 10px;
  }

  .navbar {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 576px) {
  .top-nav {
    display: block;
    text-align: center;
  }

  .contact-info, .social-icons {
    justify-content: center;
  }

  .social-icons a {
    font-size: 1.5rem; /* Make social icons slightly smaller */
  }
}

/* Responsive Logo Adjustments */
@media (max-width: 768px) {
  .logo img {
    max-width: 170px; /* Smaller logo for mobile screens */
  }
}

@media (max-width: 576px) {
  .logo img {
    max-width: 130px; /* Further reduce logo size on smaller screens */
  }
}
