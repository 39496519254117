.firstSlid-container {
    background: linear-gradient(rgb(33, 29, 74), rgb(9, 30, 54), rgb(54, 86, 114));
}

.firstCustom-carousel-img {
    width: 100%;
    height: 100%; /* Set to 100% to match carousel item height */
    object-fit: cover; 
}

.firstcarousel-title {
    font-size: 40px; /* Default size */
    color: white;
    font-weight: bold;
}

.firstcustom-button {
    width: 100%; /* Full width on small screens */
    max-width: 500px; /* Max width for larger screens */
    height: 50px; 
    border-radius: 30px; 
    background-color: #120320; 
    font-weight: bold; 
    font-size: 22px; 
    margin: 30px auto; /* Centered with auto margins */
    padding: 10px; /* Padding for better click area */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.firstcustom-button:hover {
    background-color: #1a1a1a; /* Darker shade on hover */
}

.carousel-item {
    height: 400px; /* Set your desired height for the carousel */
}

@media (max-width: 768px) {
    .firstcarousel-title {
        font-size: 22px; /* Smaller title size for small screens */
        text-align: center; /* Center title */
    }
    
    .firstcustom-button {
        font-size: 18px; /* Slightly smaller font size on small screens */
        padding: 8px; /* Adjust padding for small screens */
    }

    .p-5 {
        padding: 1rem; /* Less padding on small screens */
    }

    .carousel {
        margin-bottom: 20px; /* Add space below carousel */
    }

    .carousel-item {
        height: 300px; /* Set a fixed height for items on small screens */
    }
}
