/* Container for the video slide with gradient background */
.video-slide-container {
  background: linear-gradient(135deg, rgba(33, 29, 74, 0.8), rgba(9, 30, 54, 0.8), rgba(54, 86, 114, 0.8));
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 8px; /* Rounded corners */
}

/* Flex layout for image and text */
.video-slide-row {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: space-between;
}

/* Image styling */
.image-column {
  display: flex;
}

.image-wrapper {
  width: 100%; /* Full width */
}

.custom-image {
  width: 100%; /* Full width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(218, 193, 193, 0.2); /* Subtle shadow */
}

/* Text styles for the description section */
.video-description {
  background-color: rgba(253, 248, 251, 0.9); /* Slightly transparent light background */
  padding: 45px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.video-title {
  font-size: 2rem;
  font-style: italic;
  color: #eef8f6; /* Dark blue-green */
  margin-bottom: 20px;
  text-align: center; /* Center title */
}

.video-text {
  font-size: 1.2rem;
  color: #004d40; /* Darker teal */
  line-height: 1.6;
}

/* Responsive styling */
@media (max-width: 1200px) {
  .video-slide-row {
    flex-direction: column; /* Stack on smaller screens */
  }

  .image-column, .video-description {
    width: 100%; /* Full width */
  }

  .video-title {
    font-size: 1.5rem;
  }

  .video-text {
    font-size: 1rem;
  }
}

@media (max-width: 800px) {
  .video-title {
    font-size: 1.2rem;
  }

  .video-text {
    font-size: 0.9rem;
  }

  .custom-image {
    height: auto; /* Adjust height */
  }
}

@media (max-width: 576px) {
  .video-description {
    padding: 10px; /* Less padding */
  }

  .video-title {
    font-size: 1.1rem;
  }

  .video-text {
    font-size: 0.85rem;
  }
}
