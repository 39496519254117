/* Gallary.css */
h1 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
    color: white;
  }
  
  .card {
    border-color: aliceblue;
    box-shadow: 0px 4px 6px rgba(254, 241, 241, 0.1);
    background: linear-gradient(rgb(33, 29, 74),rgb(9, 30, 54),rgb(54, 86, 114));
    color: white;

  }
  
  
  .gallary-container{
    background: linear-gradient(rgb(33, 29, 74),rgb(9, 30, 54),rgb(54, 86, 114));
  }
 